/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Message',
  components: {},
  data: function data() {
    return {
      xh: '',
      message: {},
      ce: ''
    };
  },
  methods: {
    getMessageDetail: function getMessageDetail() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Message.getMessageDetail({
        xh: this.xh,
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.message = data; // this.message.newsValues.replace('↵','</br>')
          // this.message.newsValues.replace(/↵/g,"</br>")
          // this.message.newsValues = '这是一个测试通知，测试一下内容如果很长他的显示隐藏效果是怎么样的呀。</br>这是第二的段落，看看格式是怎么返回的。</br></br>wjn</br></br>2021.08.19'
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },

    /** 函数function 设定当前页面
     *  wzx
     *  2019.01.09
     **/
    pushHistory: function pushHistory() {
      var state = {
        title: 'title',
        url: ''
      };
      window.history.pushState(state, state.title, state.url);
    }
  },
  created: function created() {
    this.xh = this.$route.query.xh;
    this.getMessageDetail();
  },
  mounted: function mounted() {// this.pushHistory();
    // window.onpopstate = () => {
    //     this.$router.push('/MessageList');
    // }
  },
  destroyed: function destroyed() {}
};